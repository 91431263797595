const initialState = {
    id: null,
    goods: [],
    removedGoods: [],
    phone: null,
    area: null,
    street: null,
    house: null,
    entrance: null,
    floor: null,
    room: null,
    comment: null,    
    point: null,
    zones: null,
    goodPrice: 0,
    salePrice: 0,
    sale: null,
    totalPrice: 0,
    unit: null,
    deliveryMethod: 'delivery',
    deliveryPrice: 0,
    deliveryArticle: null,
    payment: null,
    changeCash: null,
    source: 'crm',
    isAggPrice: false, 
    isSite: false,
    paymentData: null,
    paymentId: null,
    user: null,
    userName: null,
    promocode: null,
    bonusCount: null
};

export default function neworders(state = initialState, action) {
  switch(action.type) {
    case 'ADD_POSITION_NEW_ORDER': {
        const good = action.good
        if ([action.setting.pizza, action.setting.wok].includes(action.good.category.id))
        {
            const dataGood = { 
                id: good.id,
                isMod: good.isMod,
                name: good.name,
                weight: good.weight,
                count: 1,
                addCount: 1,
                price: good.price ? good.price : 0,
                modifications: good.modifications ? good.modifications : null,
                oldPrice: good.oldPrice ? good.oldPrice : 0,
                category: good.category.id,
                defaultPrice: good.defaultPrice ? good.defaultPrice : null,
                defaultOldPrice: good.defaultOldPrice ? good.defaultOldPrice : null,
                index: state.goods.length > 0 ? state.goods[state.goods.length - 1].index + 1 : 1,
                description: good.description ? good.description : null
            }
            return {
                ...state,
                goods: [...state.goods, dataGood],
            };
        }

        if(action.good.category.id === action.setting.mainWok)
        {
            const dataGood = { 
                id: good.id, 
                isMod: good.isMod ? good.isMod : false,
                count: 1, 
                addCount: 1,
                isWok: good.isWok,
                category: good.category.id,
                wokData:{
                    id: good.id + (state.goods.length > 0 ? state.goods[state.goods.length - 1].index + 1 : 1),
                    main: {
                        id: good.id, 
                        article: good.article, 
                        title: good.name, 
                        price: good.price, 
                        oldPrice: good.oldPrice,                        
                    },
                    meat: {},
                    topping: {},
                    souce: {},
                    price: good.price ? good.price : 0,
                    oldPrice: good.oldPrice ? good.oldPrice : 0,
                },                 
                index: state.goods.length > 0 ? state.goods[state.goods.length - 1].index + 1 : 1
            }
            return {
                ...state,
                goods: [...state.goods, dataGood],
            };
        }

        if(state.goods.find((el) => el.id === action.good.id))
        {
            const updatedGood = state.goods.map(item =>
                item.id === action.good.id ? { ...item, count: parseInt(item.count || 1) + 1, addCount: (item.addCount || 0) + 1 } : item
            );
            return {
                ...state,
                goods: updatedGood,
            };
        }
        else
        {
            const dataGood = { 
                id: good.id,
                isMod: good.isMod ? good.isMod : false, 
                count: 1,
                addCount: 1,
                isWok: false,
                wokData: null,
                name: good.name,
                weight: good.weight,
                price: good.price ? good.price : 0,
                oldPrice: good.oldPrice ? good.oldPrice : 0,
                category: good.category.id,
                description: good.description ? good.description : null,
                index: state.goods.length > 0 ? state.goods[state.goods.length - 1].index + 1 : 1
            }
            return {
                ...state,
                goods: [...state.goods, dataGood],
            };
        }
    }

    case 'PLUS_COUNT_POSITION_NEW_ORDER': {
        const updatedGoods = state.goods.map(item =>
            item.index === action.index ? { ...item, count: parseInt(item.count || 1) + 1, addCount: (item.addCount || 0) + 1 } : item
        );
        return {
            ...state,
            goods: updatedGoods,
        };
    }

    case 'MINUS_COUNT_POSITION_NEW_ORDER': {
        const updatedGoods = state.goods.map(item =>
            item.index === action.index ? { ...item, count: parseInt(item.count || 1) - 1, addCount: (item.addCount || 0) - 1 } : item
        );
        return {
            ...state,
            goods: updatedGoods,
        };
    }

    case 'DELETE_POSITION_NEW_ORDER': {
        return {
            ...state,
            goods: state.goods.filter((item) => item.index !== action.index),
            removedGoods: [...state.removedGoods, state.goods.find((item) => item.index === action.index)]
        };
    }

    case 'CHANGE_COUNT_POSITION_NEW_ORDER': {
        const updatedGoods = state.goods.map(item =>
            item.index === action.index ? { ...item, addCount: parseInt(item.addCount || 0) + (parseInt(action.value) - parseInt(item.count)), count: action.value } : item
        );

        return {
            ...state,
            goods: updatedGoods,
        };
    }

    case 'CHANGE_MODIFICATION_NEW_ORDER': {
        const checkModPrice = (field, good, mod) => {
            if(mod.default) {
                if(field === 'price') 
                    return good.defaultPrice ? good.defaultPrice : action.checkGood.price;
                return good.defaultOldPrice ? good.defaultOldPrice : action.checkGood.oldPrice; 
            }
                if(mod.active) {
                    switch(mod.condition) {
                        case('equal'): {
                        if(mod[field]) {
                            return mod[field];
                        } else {
                            if(good[field])
                                return good[field];
                            return null;
                        }
                    }
                    case('plus'): {
                        if(good[field]) {
                            if(mod[field]) {
                                    return Math.round((good[field] + mod[field]).toFixed(0));
                            } else {
                                return good[field];
                            }
                        } else {
                            return null;
                        }
                    }
                    case('minus'): {
                        if(good[field]) {
                            if(mod[field]) {
                                return Math.round((good[field] - mod[field]).toFixed(0));
                            } else {
                                return good[field];
                            }
                        } else {
                            return null;
                        }
                    }
                    case('multiply'): {
                        if(good[field]) {
                            if(mod[field]) {
                                return Math.round((good[field] * mod[field]).toFixed(0));
                            } else {
                                return good[field];
                            }
                        } else {
                            return null;
                        }
                    }                
                        default: {
                        if(good[field]) {
                            return good[field]; 
                            } else {
                                return null;
                            }
                        }
                    }
                } else {
                    return null;
                }
        }

        const mod = action.mod

        const updatedGoods = state.goods.map(item =>
            item.index === action.index ? { 
                ...item, 
                id: !mod.default ? `${item.id}_${mod.id}` : (String(item.id).split('_')[0] ? String(item.id).split('_')[0] : item.id),
                isMod: !mod.default,
                changeMod: true,
                defaultPrice: !mod.default ? item.price : item.defaultPrice ? item.defaultPrice : action.checkGood.price,
                defaultOldPrice: !mod.default ? item.oldPrice : item.defaultOldPrice ? item.defaultOldPrice : action.checkGood.oldPrice,
                price: checkModPrice('price', item, mod),
                oldPrice: checkModPrice('oldPrice', item, mod)
            } : item
        );

        return {
            ...state,
            goods: updatedGoods,
        };
    }

    case 'ADD_ADDITIVES_NEW_ORDER': {
        switch(action.good.category.id){
            case(action.settings.mainWok): {       
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index
                    ? 
                    item.wokData.main.id !== action.good.id
                    ?
                    {
                        ...item,
                        count: (item.count || 1),
                        addCount: (item.addCount || 0),
                        wokData: {
                            id: item.wokData.id,                            
                            main: {
                                article: action.good.article,
                                id: action.good.id,
                                title: action.good.name,
                                price: action.good.price,
                                oldPrice: action.good.oldPrice                                
                            },  
                            meat: {
                                ...item.wokData.meat,                                            
                            },
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,
                            }
            
                        }
                    }
                    :
                    {
                        ...item,
                        count: parseInt(item.count || 1) + 1,
                        addCount: (item.addCount || 0) + 1,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                          
                            },
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    }
                    : 
                    item
                );
                
                return {
                    ...state,
                    goods: updatedGoods,
                };
            };

            case(action.settings.meatWok): {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index
                    ? 
                        !item.wokData.meat[action.good.id]
                        ?
                        {
                            ...item,
                            wokData: {
                                id: item.wokData.id,
                                main: {
                                    ...item.wokData.main,                                            
                                },
                                meat: {
                                    ...item.wokData.meat,
                                    [action.good.id]: {
                                        count: 1,
                                        addCount: 1,
                                        article: action.good.article,
                                        data: {
                                            id: action.good.id,
                                            title: action.good.name,
                                            price: action.good.price,
                                            oldPrice: action.good.oldPrice
                                        }
                                    }                
                                },
                                topping: {
                                    ...item.wokData.topping,
                                },
                                souce: {
                                    ...item.wokData.souce,
                                }
                
                            }
                        }
                        :
                        {
                            
                            ...item,
                            wokData: {
                                id: item.wokData.id,
                                main: {
                                    ...item.wokData.main,                                            
                                },
                                meat: {
                                    ...item.wokData.meat,
                                    [action.good.id]: {
                                        ...item.wokData.meat[action.good.id],
                                        count: parseInt(item.wokData.meat[action.good.id].count) + 1,
                                        addCount: parseInt(item.wokData.meat[action.good.id].addCount || 0) + 1
                                    }
                                },
                                topping: {
                                    ...item.wokData.topping,                                    
                                },
                                souce: {
                                    ...item.wokData.souce,                                    
                                }           
                            }
                        }
                    : 
                    item
                );

                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case(action.settings.toppingWok): {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index
                    ? 
                    !item.wokData.topping[action.good.id]
                        ?
                        {
                            ...item,
                            wokData: {
                                id: item.wokData.id,
                                main: {
                                    ...item.wokData.main,                                            
                                },
                                meat: {
                                    ...item.wokData.meat,                                          
                                },
                                topping: {
                                    ...item.wokData.topping,
                                    [action.good.id]: {
                                        count: 1,
                                        addCount: 1,
                                        article: action.good.article,
                                        data: {
                                            id: action.good.id,
                                            title: action.good.name,
                                            price: action.good.price,
                                            oldPrice: action.good.oldPrice
                                        }
                                    }      
                                },
                                souce: {
                                    ...item.wokData.souce,
                                }
                
                            }
                        }
                        :
                        {
                            ...item,
                            wokData: {
                                id: item.wokData.id,
                                main: {
                                    ...item.wokData.main,                                            
                                },
                                meat: {
                                    ...item.wokData.meat,                                          
                                },
                                topping: {
                                    ...item.wokData.topping,
                                    [action.good.id]: {
                                        ...item.wokData.topping[action.good.id],
                                        count: parseInt(item.wokData.topping[action.good.id].count) + 1,
                                        addCount: parseInt(item.wokData.topping[action.good.id].addCount || 0) + 1
                                    }
                                },
                                souce: {
                                    ...item.wokData.souce,                                    
                                }           
                            }
                        }
                    : 
                    item
                );

                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case(action.settings.souceWok): {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index
                    ? 
                        !item.wokData.souce[action.good.id]
                        ?
                        {
                            ...item,
                            wokData: {
                                id: item.wokData.id,
                                main: {
                                    ...item.wokData.main,                                            
                                },
                                meat: {
                                    ...item.wokData.meat,                                          
                                },
                                topping: {
                                    ...item.wokData.topping,
                                },
                                souce: {
                                    ...item.wokData.souce,
                                    [action.good.id]: {
                                        id: action.good.id,
                                        article: action.good.article,
                                        title: action.good.name,
                                        count: 1,
                                        addCount: 1,
                                        price: action.good.price,
                                        oldPrice: action.good.oldPrice
                                    }   
                                }            
                            }
                        }
                        :
                        {
                            ...item,
                            wokData: {
                                id: item.wokData.id,
                                main: {
                                    ...item.wokData.main,                                            
                                },
                                meat: {
                                    ...item.wokData.meat,                                          
                                },
                                topping: {
                                    ...item.wokData.topping,
                                },
                                souce: {
                                    ...item.wokData.souce,
                                    [action.good.id]: {
                                        ...item.wokData.souce[action.good.id],
                                        count: parseInt(item.wokData.souce[action.good.id].count) + 1,
                                        addCount: parseInt(item.wokData.souce[action.good.id].addCount || 0) + 1
                                    }
                                }           
                            }
                        }
                    : 
                    item
                );

                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            default: return state
        }
    }
    
    case 'PLUS_COUNT_ADDITIVES_NEW_ORDER': {
        switch(action.subtype) {
            case 'main': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        count: parseInt(item.count || 1) + 1,
                        addCount: parseInt(item.addCount || 0) + 1,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                          
                            },
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case 'meat': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,
                                [action.idAdditives]: {
                                    ...item.wokData.meat[action.idAdditives],
                                    count: parseInt(item.wokData.meat[action.idAdditives].count) + 1,
                                    addCount: parseInt(item.wokData.meat[action.idAdditives].addCount || 0) + 1
                                }
                            },                                        
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case 'topping': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: {
                                ...item.wokData.topping,
                                [action.idAdditives]: {
                                    ...item.wokData.topping[action.idAdditives],
                                    count: parseInt(item.wokData.topping[action.idAdditives].count) + 1,
                                    addCount: parseInt(item.wokData.topping[action.idAdditives].addCount || 0) + 1
                                }
                            },      
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }
            
            case 'souce': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: {
                                ...item.wokData.topping,                                
                            },   
                            souce: {
                                ...item.wokData.souce,
                                [action.idAdditives]: {
                                    ...item.wokData.souce[action.idAdditives],
                                    count: parseInt((item.wokData.souce[action.idAdditives].count || 0)) + 1,
                                    addCount: parseInt((item.wokData.souce[action.idAdditives].addCount || 0)) + 1
                                }
                            }        
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            default: return state
        }        
    }

    case 'MINUS_COUNT_ADDITIVES_NEW_ORDER': {
        switch(action.subtype) {
            case 'main': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        count: parseInt(item.count || 1) - 1,
                        addCount: parseInt(item.addCount || 0) - 1,
                        wokData: {
                            id: item.wokData.id,                            
                            main: {
                                ...item.wokData.main,                                                                         
                            },
                            meat: {
                                ...item.wokData.meat,                                          
                            },
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case 'meat': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,
                                [action.idAdditives]: {
                                    ...item.wokData.meat[action.idAdditives],
                                    count: parseInt(item.wokData.meat[action.idAdditives].count) - 1,
                                    addCount: parseInt(item.wokData.meat[action.idAdditives].addCount || 0) - 1
                                }
                            },                                        
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case 'topping': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: {
                                ...item.wokData.topping,
                                [action.idAdditives]: {
                                    ...item.wokData.topping[action.idAdditives],
                                    count: parseInt(item.wokData.topping[action.idAdditives].count) - 1,
                                    addCount: parseInt(item.wokData.topping[action.idAdditives].addCount || 0) - 1
                                }
                            },      
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }
            
            case 'souce': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: {
                                ...item.wokData.topping,                                
                            },   
                            souce: {
                                ...item.wokData.souce,
                                [action.idAdditives]: {
                                    ...item.wokData.souce[action.idAdditives],
                                    count: parseInt((item.wokData.souce[action.idAdditives].count || 1)) - 1,
                                    addCount: parseInt((item.wokData.souce[action.idAdditives].addCount || 0)) - 1
                                }
                            }        
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            default: return state
        }
    }

    case 'DELETE_ADDITIVES_NEW_ORDER': {
        switch(action.subtype) {
            case 'meat': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: Object.fromEntries(
                                Object.entries(item.wokData.meat).filter(([key, el]) => 
                                    el.data.id !== action.idAdditives 
                                )
                            ),
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case 'topping': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: Object.fromEntries(
                                Object.entries(item.wokData.topping).filter(([key, el]) => 
                                    el.data.id !== action.idAdditives 
                                )
                            ),
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }
            
            case 'souce': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: {
                                ...item.wokData.topping,                                
                            },   
                            souce: Object.fromEntries(
                                Object.entries(item.wokData.souce).filter(([key, el]) => 
                                    el.id !== action.idAdditives 
                                )
                            )
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            default: return state
        }        
    }

    case 'CHANGE_COUNT_ADDITIVES_NEW_ORDER': {
        switch(action.subtype) {
            case 'main': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        addCount: parseInt(item.addCount || 0) + (parseInt(action.value) - parseInt(item.count)),
                        count: parseInt(action.value),
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,                                                             
                            },
                            meat: {
                                ...item.wokData.meat,                                          
                            },
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case 'meat': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,
                                [action.idAdditives]: {
                                    ...item.wokData.meat[action.idAdditives],
                                    count: parseInt(action.value),
                                    addCount: parseInt(item.wokData.meat[action.idAdditives].addCount || 0) + (parseInt(action.value) - parseInt(item.wokData.meat[action.idAdditives].count)),
                                }
                            },                                        
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case 'topping': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: {
                                ...item.wokData.topping,
                                [action.idAdditives]: {
                                    ...item.wokData.topping[action.idAdditives],
                                    count: parseInt(action.value),
                                    addCount: parseInt(item.wokData.topping[action.idAdditives].addCount || 0) + (parseInt(action.value) - parseInt(item.wokData.topping[action.idAdditives].count))
                                }
                            },      
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }
            
            case 'souce': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: {
                                ...item.wokData.topping,                                
                            },   
                            souce: {
                                ...item.wokData.souce,
                                [action.idAdditives]: {
                                    ...item.wokData.souce[action.idAdditives],
                                    count: parseInt(action.value),
                                    addCount: parseInt(item.wokData.souce[action.idAdditives].addCount || 0) + (parseInt(action.value) - parseInt(item.wokData.souce[action.idAdditives].count))
                                }
                            }        
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            default: return state
        }
    }

    case 'CALCULATE_WOK_PRICE': {
        const updatedGoods = state.goods.map((item) => 
        item.index === action.index
        ?
            item.wokData
            ?
            { 
                ...item,
                wokData: {
                    id: item.wokData.id,
                    price: parseFloat(item.wokData.main.price * (item.count || 1) +
                    Object.values(item.wokData.meat).reduce((Sum, el) => el.data.price ? Sum + el.data.price * el.count : Sum + 0, 0) + 
                    Object.values(item.wokData.topping).reduce((Sum, el) => el.data.price ? Sum + el.data.price * el.count : Sum + 0, 0) + 
                    Object.values(item.wokData.souce).reduce((Sum, el) => (el.price && el.count) ? Sum + el.price * el.count  : Sum + 0, 0)).toFixed(2),
                    oldPrice: parseFloat(item.wokData.main.oldPrice * (item.count || 1) +
                    Object.values(item.wokData.meat).reduce((Sum, el) => el.data.oldPrice ? Sum + el.data.oldPrice * el.count : Sum + 0, 0) + 
                    Object.values(item.wokData.topping).reduce((Sum, el) => el.data.oldPrice ? Sum + el.data.oldPrice * el.count : Sum + 0, 0) + 
                    Object.values(item.wokData.souce).reduce((Sum, el) => (el.oldPrice && el.count) ? Sum + el.oldPrice * el.count : Sum + 0, 0)).toFixed(2),
                    main: {
                        ...item.wokData.main,       
                    },
                    meat: {
                        ...item.wokData.meat,                                          
                    },
                    topping: {
                        ...item.wokData.topping,
                    },
                    souce: {
                        ...item.wokData.souce,                                
                    }            
                }
            } 
            :
            item
        :
        item
    )

    return {
        ...state,
        goods: updatedGoods,
    };
    }

    case 'SET_SALE_PERCENT_NEW_ORDER': {
        if(action.value !== state.sale)
            return {
                ...state,
                sale: action.value,
                salePrice: 0
            };

        return {
            ...state,
            sale: null,
            salePrice: 0
        };
    }

    case 'SET_SALE_SUM_NEW_ORDER': {
        if(parseFloat(action.value) > parseFloat(state.goodPrice))
            return {
                ...state,
                sale: null,
                salePrice: parseFloat(state.goodPrice)
            };
            
        return {
            ...state,
            sale: null,
            salePrice: parseFloat(action.value)
        };
    }

    case 'CALCULATION_TOTALPRICE_NEW_ORDER': {
        let saleCheck = state.salePrice;

        const checkGoodPrice = (goodId, price, oldPrice) => {
            if(!state.isAggPrice) {
                if(price) {
                    return price;
                } else {
                    return action.goodsChek.find((el) => el.id === parseInt(goodId)).price ? action.goodsChek.find((el) => el.id === parseInt(goodId)).price : 0;
                }
            } else {
                if(oldPrice) {
                    return oldPrice;
                } else {
                    return action.goodsChek.find((el) => el.id === parseInt(goodId)).oldPrice ? action.goodsChek.find((el) => el.id === parseInt(goodId)).oldPrice : 0;
                }
            }
        }

        const GoodPrice = (state.goods.reduce((Sum, item) => 
            !item.wokData
            ?

                    Sum + (parseFloat(checkGoodPrice(item.id, item.price, item.oldPrice) || 0) * parseInt(item.count || 1))               
            :
                !state.isAggPrice
                ?
                Sum + parseFloat(item.wokData.price) 
                :
                Sum + parseFloat(item.wokData.oldPrice || 0), 0) + (parseFloat(state.deliveryPrice) || 0)).toFixed(2);
                
        
        if(state.sale !== null)
            saleCheck = parseFloat(GoodPrice * state.sale).toFixed(2);

        return { 
            ...state,
            goodPrice: GoodPrice,
            totalPrice: parseFloat(GoodPrice - saleCheck).toFixed(2),
            salePrice: parseFloat(saleCheck).toFixed(2)
        };
    }

    case 'CHANGE_POINT_NEW_ORDER': {
        return { 
            ...state,
            point: action.value,
            deliveryArticle: null,
            deliveryPrice: 0
            }
    }

    case 'ADD_DELIVERY_PRICED_NEW_ORDER': {
        return {
            ...state,
            deliveryArticle: action.article,
            deliveryPrice: action.price
        }
    }

    case 'SET_ZONES_NEW_ORDER': {
        return { 
            ...state,
            zones: action.value
            }
    }

    case 'CHANGE_DELIVERY_METHOD_NEW_ORDER': {
        return { 
            ...state,
            deliveryMethod: action.value
            }
    }

    case 'CHANGE_INFORMATION_NEW_ORDER': {
                return { 
                    ...state,
                    [action.subtype]: action.value
                    }          
    }

    case 'CHANGE_AGG_PRICE_NEW_ORDER': {
        return { 
            ...state,
            isAggPrice: action.value
            }
    }

    case 'CHANGE_PAYMENT_NEW_ORDER': {
        if(action.value !== state.payment)
        return {
            ...state,
            payment: action.value,
            changeCash: null
            }

        return {
            ...state,
            payment: null,
            changeCash: null 
            }
    }

    case 'CHANGE_CHANGECASH_NEW_ORDER': {
        if(action.value !== state.changeCash)
        return {
            ...state,
            changeCash: action.value            
            }

        return {
            ...state,
            changeCash: null         
            }
    }

    case 'OPEN_EDIT_ORDER': {
        const order = action.order;
        let goodsWithIndex = []
        const goodDescription = (good) => {
            if(good.description && good.description !== '')
                return good.description;

            let checkGood = action.goodsChek.find((el) => el.id === parseInt(good.id))
            if(checkGood && checkGood.description && checkGood.description !== '')
                return checkGood.description;

            return null
        }

        if(order.isAggPrice) {
            goodsWithIndex = order.goods.map((good, index) => ({
                ...good,
                oldPrice: good.price,
                description: goodDescription(good),
                index
            }))
        } else {
            goodsWithIndex = order.goods.map((good, index) => ({
                ...good, 
                description: goodDescription(good),
                index 
            }));
        }

        const sourceCheck = (source, isSite) => {
            if(source !== 'crm' && isSite)
                return 'site';

            if(source !== 'crm' && !isSite)
                return 'app';

            return 'crm';
        }

        return {
            ...state,
            id: order.id,
            goods: goodsWithIndex,
            phone: order.phone,
            area: order.area,
            street: order.street,
            house: order.house,
            entrance: order.entrance,
            floor: order.floor,
            room: order.room,
            comment: order.comment,
            deliveryMethod: order.deliveryMethod,
            payment: order.payment,
            paymentData: order.paymentData,
            paymentId: order.paymentId,
            point: action.point,
            city: order.city,
            zone: order.zone,
            goodPrice: parseFloat(order.goodsPrice),
            salePrice: parseFloat(order.salePrice),
            sale: order.sale,
            totalPrice: parseFloat(order.totalPrice),
            unit: order.unit,
            changeCash: order.changeCash,
            source: sourceCheck(order.source, order.isSite),
            isAggPrice: order.isAggPrice,
            isSite: order.isSite,
            deliveryPrice: order.deliveryPrice,
            deliveryArticle: order.deliveryArticle,
            isArchive: order.isArchive,
            deliveryTime: order.deliveryTime,
            timeMethod: order.timeMethod,
            deliveryTimeComment: order.deliveryTimeComment,
            user: order.user,
            userName: order.userName,
            promocode: order.promocode,
            bonusCount: order.bonusCount,
            visible_datetime: order.visible_datetime,
            authorRole: order.authorRole,
            changeLog: order.changeLog
        }
    }

    case 'CLEAR_NEW_ORDER': {
        return {
            ...state,
            id: null,
            goods: [],
            removedGoods: [],
            phone: null,
            area: null,
            street: null,
            house: null,
            entrance: null,
            floor: null,
            room: null,
            comment: null,
            deliveryMethod: 'delivery',
            payment: null,
            paymentData: null,
            paymentId: null,
            point: null,
            city: null,
            zone: null,
            goodPrice: 0,
            salePrice: 0,
            sale: null,
            totalPrice: 0,
            unit: null,
            changeCash: null,
            source: 'crm',
            isAggPrice: false,
            isSite: false,
            deliveryPrice: null,
            deliveryArticle: null,
            isArchive: false,
            deliveryTime: null,
            timeMethod: 'fast',
            deliveryTimeComment: null,
            user: null,
            userName: null,
            promocode: null,
            bonusCount: null,
            visible_datetime: null,
            authorRole: null,
            changeLog: null
        };
    }

    default: return state;
  }
}