const initialState = {
    list: [],
    detail: null,
    edited: null,
    delete: null,
    add: null,
    paging: {
      start: 0,
      limit: 10
    },
    sorting: {
      field: 'id',
      type: 'desc'
    },
    filters: {
      city: null,
      startDate: null,
      endDate: null,
    },
    count: 0
};

export default function promotions(state = initialState, action) {
  switch(action.type) {
    case 'SET_PROMOTIONS': {
          return {
              ...state,
              list: action.promotions
          };
    }
    case 'SET_PROMOTIONS_FILTERS': {
      return {
          ...state,
          filters: action.filters
      };
    }
    case 'SET_PROMOTIONS_PAGING': {
      return {
          ...state,
          paging: action.paging
      };
}
    case 'SET_PROMOTIONS_COUNT': {
      return {
          ...state,
          count: action.count
      };
    }
    case 'SET_PROMOTION': {
      return {
          ...state,
          detail: action.promotion
      };
    }
    case 'SET_EDITED_PROMOTION': {
        return {
            ...state,
            edited: action.promotion
        };
    }
    case 'SET_ADD_PROMOTION': {
        return {
            ...state,
            add: action.promotion
        };
    }
    case 'SET_DELETE_PROMOTION': {
        return {
            ...state,
            delete: action.promotion
        };
    }
    default: return state;
  }
}