import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Input from '../utils/Input';
import Switch from '../utils/Switch';
import DateInput from '../utils/DateInput';
import Select from 'react-select';
import { countsType, deliveryType, promocodeMainType, promocodeType } from './constants';
import { isNumber } from 'lodash';

const AddForm = (props) => {
    const [countType, setCountType] = useState('one');
    const [countOfCode, setCountOfCode] = useState();
    const [mask, setMask] = useState('');
    const { add: values, setAddPromocode, addPromocode, fetchPromocodes, loadingCity, cities, loadingGoods, goods, categories } = props;

    useEffect(() => {
        return () => setAddPromocode(null)
    }, [setAddPromocode]);

    const cancelAdd = () => {
        setAddPromocode(null);
    }

    const addPromocodeHandler = async () => {
       const result = await addPromocode({ ...values, countType });
        if (result) {
            fetchPromocodes();
            setAddPromocode(null);
        }
    }

    const onChangeAdd = useCallback((field, value) => {
        const updated = {
            ...values,
            [field]: value
        };

        if (field === 'cities' && !value) {
            updated.cities = [];
        }

        if (field === 'type') {
            updated.persent = 0;
            updated.goods = null;
            updated.category = null;
            updated.value = null;
        }

        setAddPromocode(updated);
    }, [setAddPromocode, values]);

    const onChangeGoods = useCallback((selected) => {
        const data = selected ? selected.map(item => {
            if (item.sale === null || item.sale === undefined) {
                return { ...item, sale: null, persent: 0 };
            }
            return item;
        }) : []
        onChangeAdd('goods', data);
    }, [onChangeAdd]);

    const onChangeGoodsItem = useCallback((field, index, value) => {
        const goods = [...values.goods];
        goods[index][field] = value
        onChangeAdd('goods', goods);
    }, [onChangeAdd, values.goods]);

    const isValidAdd = useMemo(() => {
        if (!values.name || !values.minPrice) {
            return false
        }

        if (values.type === 'order' || values.type === 'category') {
            if (!values.value) return false;
        }

        if (values.type === 'goods') {
            if (!values.goods || values.goods.length === 0) return false;
            if (values.goods.some(item => !item.sale)) return false;
        }

        if (values.type === 'addGoods') {
            if (!values.goods || values.goods.length === 0) return false;
            if (values.goods.some(item => !isNumber(item.sale))) return false;
            if (values.goods.some(item => !item.article)) return false;
        }

        if (values.type === 'category') {
            if (!values.category) return false;
        }

        if ((values.startTimeDate && !values.endTimeDate) || (!values.startTimeDate && values.endTimeDate)) {
            return false;
        }

        return true;
    }, [values.category, values.endTimeDate, values.goods, values.minPrice, values.name, values.startTimeDate, values.type, values.value]);

    const footer = useMemo(() => {
        return (
            <div className="kt-form__actions">
                <button onClick={cancelAdd} style={{ marginRight: 10 }} className="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u">
                    Отмена
                </button>
                <button onClick={addPromocodeHandler} disabled={!isValidAdd} className="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u">
                    Сохранить
                </button>
            </div>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelAdd, isValidAdd])

    const generateCode = useCallback(() => {
        return mask.replace(/#|%/g, v => v === '%'
            ? String.fromCharCode(Math.floor(Math.random() * 26) + 97)
            : Math.floor(Math.random() * 10));
    }, [mask]);

    const generateCodes = useCallback((e) => {
        e.preventDefault();
        const count = countOfCode > 10 ? 10 : countOfCode < 1 ? 1 : countOfCode;
        const data = [...Array(count)].map(() => generateCode(mask));
        onChangeAdd('name', data.join(' '));
    }, [countOfCode, generateCode, mask, onChangeAdd]);

    const countBlock = useMemo(() => {
        return (
            <>
                <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Количество</label>
                    <div className="col-lg-9 col-xl-9">
                        <select onChange={(e) => setCountType(e.target.value)} value={countType} className="form-control">
                            {
                                Object.keys(countsType).map((key) => (
                                    <option key={key} value={key}>{countsType[key]}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                {countType === 'many' && (
                     <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label" />
                        <div className="col-lg-3 col-xl-3">
                            <Input min={1} max={10} onChange={(e) => setCountOfCode(+e.target.value)} label="Значение" description="от 1 до 10" placeholder="Введите число" type="number" disableGroup={true} value={countOfCode} />
                        </div>
                        <div className="col-lg-4 col-xl-4">
                            <Input onChange={(e) => setMask(e.target.value)} label="Маска" description="# - число, % - символ" placeholder="Введите маску" type="string" disableGroup={true} value={mask} />
                        </div>
                        <div className="col-lg-2 col-xl-2">
                            <button onClick={generateCodes} disabled={!countOfCode || !mask} style={{ marginTop: '26px' }} className="btn btn-success">Создать</button>
                        </div>
                    </div>
                )}
            </>
        );
    }, [countOfCode, countType, generateCodes, mask])

    const namesCodeBlock = useMemo(() => {
        return (
            <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Промокод*</label>
                <div className="col-lg-9 col-xl-9">
                    <Input disabled={countType === 'many'} onChange={(e) => onChangeAdd('name', e.target.value)} disableGroup={true} value={values.name}/>
                </div>
            </div>
        );
    }, [countType, onChangeAdd, values.name]);

    const mainTypeBlock = useMemo(() => {
        return (
            <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Тип</label>
                <div className="col-lg-9 col-xl-9">
                    <select onChange={(e) => onChangeAdd('mainType', e.target.value)} value={values.mainType} className="form-control">
                        {
                            Object.keys(promocodeMainType).map((key) => (
                                <option key={key} value={key}>{promocodeMainType[key]}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
        );
    }, [onChangeAdd, values.mainType]);

    const cityBlock = useMemo(() => {
        return (
            <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Город</label>
                <div className="col-lg-9 col-xl-9">
                    <Select
                        isLoading={loadingCity}
                        isDisabled={loadingCity}
                        isMulti
                        menuPortalTarget={document.body}
                        closeMenuOnSelect={true}
                        value={values.cities ? values.cities.map(item => ({
                            value: item.id || item.value,
                            label: item.name || item.label
                        })) : []}
                        onChange={(selected) => onChangeAdd('cities', selected)}
                        options={
                            cities.map(item => ({
                                value: item.id,
                                label: item.name
                            }))
                        }
                        placeholder="Любой"
                        noOptionsMessage={() => 'Нет городов'}
                    />
                </div>
            </div>
        );
    }, [cities, loadingCity, onChangeAdd, values.cities]);

    const variantGoods = useMemo(() => {
        return (
            <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Список товаров</label>
                <div className="col-lg-9 col-xl-9">
                    <Select
                        isLoading={loadingGoods}
                        isDisabled={loadingGoods}
                        menuPortalTarget={document.body}
                        isMulti
                        isSearchable
                        closeMenuOnSelect={true}
                        options={goods}
                        placeholder="Товары"
                        noOptionsMessage={() => 'Нет товаров'}
                        onChange={onChangeGoods}
                        value={values.goods}
                    />

                    {
                        values.goods && values.goods.length > 0 && (
                            <div style={{ marginTop: 15 }}>
                                {
                                    values.goods.map((good, index) => (
                                        <div style={{ marginTop: 5, alignItems: 'center' }} className="row">
                                            <div className="col-xl-3 col-lg-3">{good.label}</div>
                                            <div className="col-lg-4 col-xl-4">
                                                <Input placeholder="Скидка" onChange={(e) => onChangeGoodsItem('sale', index , e.target.value ? +e.target.value : null)} type="number" disableGroup={true} value={good.sale}/>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }} className="col-lg-4 col-xl-4">  
                                                <Switch nomargin={true} onChange={(value) => onChangeGoodsItem('persent', index, +value)} checked={!!good.persent}/>
                                                <div style={{ marginLeft: 10 }}>Процент</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }, [goods, loadingGoods, onChangeGoods, onChangeGoodsItem, values.goods]);

    const variantAddGoods = useMemo(() => {
        return (
            <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Список товаров</label>
                <div className="col-lg-9 col-xl-9">
                    <Select
                        isLoading={loadingGoods}
                        isDisabled={loadingGoods}
                        menuPortalTarget={document.body}
                        isMulti
                        isSearchable
                        closeMenuOnSelect={true}
                        options={goods}
                        placeholder="Товары"
                        noOptionsMessage={() => 'Нет товаров'}
                        onChange={onChangeGoods}
                        value={values.goods}
                    />

                    {
                        values.goods && values.goods.length > 0 && (
                            <div style={{ marginTop: 15 }}>
                                {
                                    values.goods.map((good, index) => (
                                        <div style={{ marginTop: 5, alignItems: 'center' }} className="row">
                                            <div className="col-xl-3 col-lg-3">{good.label}</div>
                                            <div className="col-lg-4 col-xl-4">
                                                <Input placeholder="Цена" onChange={(e) => onChangeGoodsItem('sale', index , e.target.value ? +e.target.value : null)} type="number" disableGroup={true} value={good.sale}/>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }} className="col-lg-4 col-xl-4">
                                                <Input placeholder="Артикул" onChange={(e) => onChangeGoodsItem('article', index, e.target.value)} disableGroup={true} value={good.article}/>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }, [goods, loadingGoods, onChangeGoods, onChangeGoodsItem, values.goods]);

    const variantCategoryBlock = useMemo(() => {
        return (
            <>
                 <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Категория</label>
                    <div className="col-lg-9 col-xl-9">
                        <Select
                            isLoading={loadingGoods}
                            isDisabled={loadingGoods}
                            menuPortalTarget={document.body}
                            isSearchable
                            closeMenuOnSelect={true}
                            options={categories}
                            placeholder="Категории"
                            noOptionsMessage={() => 'Нет категорий'}
                            onChange={(selected) => onChangeAdd('category', selected)}
                            value={values.category}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Значение*</label>
                    <div className="col-lg-9 col-xl-9">
                        <Input onChange={(e) => onChangeAdd('value', +e.target.value)} type="number" disableGroup={true} value={values.value}/>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Процент</label>
                    <div className="col-lg-9 col-xl-9">
                        <Switch nomargin={true} onChange={(value) => onChangeAdd('persent', +value)} checked={!!values.persent}/>
                    </div>
                </div>
            </>
        );
    }, [categories, loadingGoods, onChangeAdd, values.category, values.persent, values.value]);

    const variantOrderBlock = useMemo(() => {
        return (
            <>
                 <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Скидка*</label>
                    <div className="col-lg-9 col-xl-9">
                        <Input onChange={(e) => onChangeAdd('value', +e.target.value)} type="number" disableGroup={true} value={values.value}/>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Процент</label>
                    <div className="col-lg-9 col-xl-9">
                        <Switch nomargin={true} onChange={(value) => onChangeAdd('persent', +value)} checked={!!values.persent}/>
                    </div>
                </div>
            </>
        );
    }, [onChangeAdd, values.persent, values.value]);

    const variantBlock = useMemo(() => {
        return (
            <>
                <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Вариант</label>
                    <div className="col-lg-9 col-xl-9">
                        <select onChange={(e) => onChangeAdd('type', e.target.value)} value={values.type} className="form-control">
                            {
                                Object.keys(promocodeType).map((key) => (
                                    <option key={key} value={key}>{promocodeType[key]}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                {values.type === 'goods' && variantGoods}
                {values.type === 'addGoods' && variantAddGoods}
                {values.type === 'category' && variantCategoryBlock}
                {values.type === 'order' && variantOrderBlock}
            </>
        );
    }, [onChangeAdd, values.type, variantAddGoods, variantCategoryBlock, variantGoods, variantOrderBlock]);

    const periodBlock = useMemo(() => {
        return (
            <>
                <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Период действия</label>
                    <div className="col-lg-9 col-xl-9">
                        <div className="dateBlock">
                            <DateInput
                                placeholderText="Начало периода"
                                selected={values.startDate}
                                onChange={(date) => onChangeAdd('startDate', date)}
                            />
                            <div>-</div>
                            <DateInput
                                placeholderText="Конец периода"
                                selected={values.endDate}
                                onChange={(date) => onChangeAdd('endDate', date)}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Часы работы</label>
                    <div className="col-lg-9 col-xl-9">
                        <div className="dateBlock">
                            <DateInput
                                placeholderText="Начало периода"
                                showTimeSelectOnly
                                dateFormat="HH:mm"
                                selected={values.startTimeDate}
                                onChange={(date) => onChangeAdd('startTimeDate', date)}
                            />
                            <div>-</div>
                            <DateInput
                                placeholderText="Конец периода"
                                selected={values.endTimeDate}
                                showTimeSelectOnly
                                dateFormat="HH:mm"
                                onChange={(date) => onChangeAdd('endTimeDate', date)}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }, [onChangeAdd, values.endDate, values.endTimeDate, values.startDate, values.startTimeDate]);

    const totalSumBlock = useMemo(() => {
        return (
            <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Сумма заказа*</label>
                <div className="col-lg-9 col-xl-9">
                    <Input onChange={(e) => onChangeAdd('minPrice', +e.target.value)} type="number" disableGroup={true} value={values.minPrice}/>
                </div>
            </div>
        );
    }, [onChangeAdd, values.minPrice]);

    const deliveryBlock = useMemo(() => {
        return (
            <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Тип доставки</label>
                <div className="col-lg-9 col-xl-9">
                    <select onChange={(e) => onChangeAdd('delivery', e.target.value)} value={values.delivery} className="form-control">
                        {
                            Object.keys(deliveryType).map((key) => (
                                <option key={key} value={key}>{deliveryType[key]}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
        );
    }, [onChangeAdd, values.delivery]);

    const activeBlock = useMemo(() => {
        return (
            <div className="form-group form-group-last row">
                <label className="col-xl-3 col-lg-3 col-form-label">Включен</label>
                <div className="col-lg-9 col-xl-9">
                    <Switch nomargin={true} onChange={(value) => onChangeAdd('active', +value)} checked={!!values.active}/>
                </div>
            </div>
        );
    }, [onChangeAdd, values.active]);

    const content = useMemo(() => {
        return (
            <div className="kt-section__body">
                {countBlock}
                {namesCodeBlock}
                {mainTypeBlock}
                {cityBlock}
                <hr className="hrLine"/>
                {variantBlock}
                <hr className="hrLine"/>
                {periodBlock}
                <hr className="hrLine"/>
                {totalSumBlock}
                {deliveryBlock}
                {activeBlock}
            </div>
        );
    }, [activeBlock, cityBlock, countBlock, deliveryBlock, mainTypeBlock, namesCodeBlock, periodBlock, totalSumBlock, variantBlock]);

    return (
        <div className="kt-grid  kt-wizard-v1 kt-wizard-v1--white" id="kt_apps_projects_add" data-ktwizard-state="first">
            <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v1__wrapper">
                <form className="kt-form">
                    <div className="kt-wizard-v1__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                        <div className="kt-heading kt-heading--md">Добавление промокода</div>
                        <div className="kt-section kt-section--first">
                            <div className="kt-wizard-v1__form">
                                <div className="row">
                                    <div className="col-xl-12">
                                        {content}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {footer}
                </form>
            </div>
        </div>
    );
}

export default AddForm;