export const deliveryType = {
    all: 'Любая',
    own: 'Самовывоз',
    delivery: 'Доставка'
}

export const promocodeType = {
    order: 'Скидка на заказ',
    goods: 'Скидка на товары',
    category: 'Скидка на категорию',
    addGoods: 'Товар в корзину',
};

export const promocodeMainType = {
    disposable: 'Одноразовый',
    reusable: 'Многоразовый',
};

export const countsType = {
    one: 'Один',
    many: 'Несколько',
};

export const promocodeStatus = {
    active: 'Активный',
    activated: 'Активирован',
    deactivated: 'Отменен',
}