const initialState = {
    list: [],
    paging: {
      start: 0,
      limit: 10
    },
    sorting: {
      field: 'id',
      type: 'desc'
    },
    count: 0,
    hidden: false
};

export default function historyOrders(state = initialState, action) {
    switch(action.type) {
      case 'SET_HISTORY_ORDERS': {
            return {
                ...state,
                list: action.orders
            };
      }
      case 'SET_HISTORY_ORDERS_PAGING': {
        return {
            ...state,
            paging: action.paging
        };
      }
      case 'SET_HISTORY_ORDERS_COUNT': {
        return {
            ...state,
            count: action.count,
            hidden: action.count === 0 ? true : false
        };
      }
      case 'CLEAR_HISTORY_ORDERS': {
        return {
            ...state, 
            list: [],
            paging: {
            start: 0,
            limit: 10
            },
            sorting: {
            field: 'id',
            type: 'desc'
            },
            count: 0,
            hidden: false
        };
      }
    default: return state;
    }
  }