import { createAction } from 'redux-actions';
import { makeActionType } from '../utils';
import { urls, baseUrl } from '../constants/app';
import request from './utils';
import moment from 'moment';
import axios from 'axios';
import { setError, resetError } from './errors';
import { createFormData } from '../utils/common';
axios.defaults.baseURL = baseUrl;

const namespace = 'NEWS';

export const setNews = createAction(makeActionType(namespace, ['SET']));
export const setNewsDetail = createAction(makeActionType(namespace, ['SET', 'DETAIL']));
export const setPagingNews = createAction(makeActionType(namespace, ['SET', 'PAGING']));
export const setSortingNews = createAction(makeActionType(namespace, ['SET', 'SORTING']));
export const setFiltersNews = createAction(makeActionType(namespace, ['SET', 'FILTERS']));
export const setCountNews = createAction(makeActionType(namespace, ['SET', 'COUNT']));

export function fetchNews(dataParams = {}) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_LOADER', field: 'news', value: true });
        dispatch(resetError('news'));
        const newsState = getState().news;

        const { paging, sorting, filters, } = newsState;

        const params = {...dataParams};

        Object.keys(filters).forEach(key => {
            if (filters[key]) {
                dataParams[key] = filters[key].value;
                params[key] = filters[key].value;
            }
        });

        params['_start'] = paging.start;
        params['_limit'] = paging.limit;

        params['_sort'] = `${sorting.field}:${sorting.type}`;

        if (filters.createdStartDate) {
            dataParams['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
            params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

        if (filters.createdEndDate) {
            dataParams['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
            params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

        request({ method: 'get', url: urls.news+'/count', params: dataParams })
            .then(result => {
                dispatch(setCountNews(result));
                return;
            })
            .then(() => {
                return request({ method: 'get', url: urls.news, params }).then((response) => {
                    dispatch(setNews(response))
                    dispatch({ type: 'SET_LOADER', field: 'news', value: false });
                })
            })
            .catch(error => {
                dispatch(setError(error, 'news'));
                dispatch({ type: 'SET_LOADER', field: 'news', value: false });
            });
        }
}

export function fetchNewsDetail(id) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'news', value: true });
        dispatch(resetError('news'));
       
        try {
            const result = await request({ method: 'get', url: `${urls.news}/${id}` });
            dispatch(setNewsDetail(result));
            dispatch({ type: 'SET_LOADER', field: 'news', value: false });
        } catch(error) {
            dispatch(setError(error, 'news'));
            dispatch({ type: 'SET_LOADER', field: 'news', value: false });
        }
    };
}

export function addNews(values) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'newsAdd', value: true });
        const data = createFormData(values);

        try {
            const result = await request({ method: 'post', url: urls.news, data, headers: { "Content-Type": "multipart/form-data" }, });
            if (result) {
                dispatch({ type: 'SET_LOADER', field: 'newsAdd', value: false });
                return true;
            }
        } catch(error) {
            dispatch(setError({ status: 506 }, 'newsAdd'));
            dispatch({ type: 'SET_LOADER', field: 'newsAdd', value: false });
            return false;
        }
    }
}

export function updateNews(id, values) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'newsAdd', value: true });
        const data = createFormData(values);

        try {
            const result = await request({ method: 'put', url: `${urls.news}/${id}`, data, headers: { "Content-Type": "multipart/form-data" }, });
            if (result) {
                dispatch({ type: 'SET_LOADER', field: 'newsAdd', value: false });
                return true;
            }
        } catch(error) {
            dispatch(setError({ status: 506 }, 'newsAdd'));
            dispatch({ type: 'SET_LOADER', field: 'newsAdd', value: false });
            return false;
        }
    }
}

export function deleteNews(id) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'news', value: true });
        try {
            await request({ method: 'delete', url: `${urls.news}/${id}` });
            dispatch(fetchNews());
        } catch(error) {
            dispatch(setError({ status: 507 }, 'news'));
            dispatch({ type: 'SET_LOADER', field: 'news', value: false });
        }
    }
}