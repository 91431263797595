export const deliveryType = {
    all: 'Любая',
    own: 'Самовывоз',
    delivery: 'Доставка'
}

export const promotionType = {
    order: 'Скидка на заказ',
    goods: 'Скидка на товары',
    category: 'Скидка на категорию',
    addGoods: 'Товар в корзину',
};
