import React, { useRef } from "react"
import { useOnClickOutside } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModalAction } from "../../action";
import { withRouter  } from 'react-router-dom';
import moment from "moment";
const ChangeLogOrderModal = () => {
    const ref = useRef();
    const dispatch = useDispatch();
    const editedOrder = useSelector(state => state.orders.detail);
    const onClose = () => dispatch(setActiveModalAction({ field: 'changeLogOrder', value: null }));
    
    useOnClickOutside(ref, onClose);
    
    const renderChangeLog = (log) => {
        let checkChangeLog = null;

        if(typeof log === 'string') {
            checkChangeLog = JSON.parse(log)
        } else {
            checkChangeLog = log
        }

        if (typeof checkChangeLog === 'object' && !Array.isArray(checkChangeLog)) {
            checkChangeLog = Object.values(checkChangeLog);
        }

        return(
            <div style={{ position: 'relative' }}>
                {checkChangeLog.sort((a, b) => moment(b.date, 'DD.MM.YYYY HH:mm') - moment(a.date, 'DD.MM.YYYY HH:mm')).map((item) => {
                    const { user, date, ...data } = item;
                    return(
                        <div className='dropdown_row_changeLog' style={{ whiteSpace: 'wrap' }}>
                            <div style={{ color: '#000', fontWeight: 600 }}>{`Время изменения: ${date}`}</div>
                            <div style={{ color: '#000' }}>{`Автор изменения: ${user}`}</div>
                            {Object.values(data).map((el, index) => {
                            if (typeof el === 'object' && el !== null) {
                                return Object.values(el).map((change, idx) => (
                                    <div key={idx} ><span style={{ color: '#000' }}>{change}</span></div>
                                ));
                            }
                            return <div key={index}><span style={{ color: '#000' }}>{el}</span></div>; 
                        })}
                        </div>
                    );
                })}
            </div>
        )
    }

    return(
        <form>
            <div  className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog modal-lg modal-dialog-scrollable" style={{ height: '90%', alignItems: 'center', justifyContent: 'center' }}>
                    <div ref={ref} className="modal-content" style={ window.innerWidth > 1024 ? {} : { maxHeight: 'calc(100vh - 200px)' }}>
                        <div className="modal-header">
                            <h5 className="modal-title"> История изменений заказа №{editedOrder.id} </h5>
                            <button onClick={onClose} type="button" className="close"></button>
                        </div>
                        <div className="modal-body" style={{ paddingLeft: 0, paddingRight: 0 }}>
                            {renderChangeLog(editedOrder.changeLog)}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={onClose}>Закрыть</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default withRouter(ChangeLogOrderModal);