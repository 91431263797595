import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/utils/Loader';
import Portlet from '../components/utils/Portlet';
import Content from '../components/layout/Content';
import ReactPaginate from 'react-paginate';
import List from '../components/promotion/List';
import AddForm from '../components/promotion/Add';
import EditForm from '../components/promotion/Edit';
import Detail from '../components/promotion/Detail';
import { fetchPromotions, setDetailPromotion, setEditedPromotion, updatePromotion, deletePromotion, addPromotion, setAddPromotion, setPromotionsFilters, setPromotionsPaging } from '../action/app';
import ErrorContainer from '../components/utils/ErrorContainer';
import { fetchCities } from '../action/handbook';
import { fetchGoods, fetchCategories } from '../action/goods';
import { createSelector } from 'reselect'
import DateInput from '../components/utils/DateInput';
import moment from 'moment';
import Select from 'react-select';
import { setUIvisible } from '../action/UImobile';
import HeadMobileActionMenu from '../components/utils/HeadMobileActionMenu';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class PromotionPage extends Component {
    componentDidMount() {
        this.props.fetchPromotions();
        this.props.fetchCities();
        this.props.fetchGoods();
        this.props.fetchCategories();
        this.props.setUIvisible(true, true);
    }

    state = {
        currentPage: 0
    }

    setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        this.setState({ currentPage: 0 });
        this.props.setPromotionsPaging(updatedPaging);
        this.props.fetchPromotions();
    }

    handlePageClick = data => {
        const { paging } = this.props;
        this.setState({ currentPage: data.selected }, () => {
            let nextStart =  data.selected * paging.limit;
            const updatedPaging = {...paging, start: nextStart};
            this.props.setPromotionsPaging(updatedPaging);
            this.props.fetchPromotions();
        });
    };

    chekMarginPagesDisplayed = () => {
        const userDisplayWidth = window.innerWidth;

        if(userDisplayWidth < 400)
            return 1;

        if(userDisplayWidth < 550)
            return 2;

        if(userDisplayWidth < 850)
            return 3;

        if(userDisplayWidth < 1250)
            return 5;

        return 8;
    }

    chekPageRangeDisplayed = () => {
        const userDisplayWidth = window.innerWidth;

        if(userDisplayWidth < 550)
            return 2;

        if(userDisplayWidth < 850)
            return 3;

        return 5;
    }

    renderPagination() {
        const { count, paging } = this.props;
        const pages = Math.ceil(+count / +paging.limit);

        return (
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={this.chekMarginPagesDisplayed()}
                    pageRangeDisplayed={this.chekPageRangeDisplayed()}
                    forcePage={this.state.currentPage}
                    onPageChange={this.handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                    disabled={this.props.add}
                />

                    <div className="kt-datatable__pager-info">
                        <div className="kt-datatable__pager-size">
                            <select disabled={this.props.add} onChange={this.setPageLimit} value={paging.limit} className="form-control">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            
                        </div>
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                            ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                       
                    </div>
            </div>
    
        );
    }

    addPromotion = () => {
        const promotion = {
            name: null,
            value: null,
            delivery: 'all',
            persent: 0,
            active: 0,
            minPrice: null,
            type: 'order',
            city: { value: '', label: 'Любой' },
            startDate: null,
            startTimeDate: null,
            endDate: null,
            endTimeDate: null,
            goods: null,
            category: null,
            cities: []
        };        
        this.props.setAddPromotion(promotion);
    }

    onChangeDateInput = (field) => (date) => {
        const { filters } = this.props;

        const updated = {
            ...filters,
            [field]: date,
        };

        if (field === 'createdStartDate') {
            updated.createdEndDate = moment(date).add({ days: 30 }).toDate()
        }

        this.props.setPromotionsFilters(updated);
    }

    setPromotionsFilters = (field) => (data) => {
        const { filters, paging } = this.props;
        const updated = {
            ...filters,
            [field]: data
        }

        this.props.setPromotionsFilters(updated);
        this.props.setPromotionsPaging({ ...paging, start: 0 });
        this.props.fetchPromotions();
    }

    onSearch = () => {
        const { paging } = this.props;
        this.props.setPromotionsPaging({ ...paging, start: 0 });
        this.setState({ currentPage: 0 });
        this.props.fetchPromotions();
    }

    resetFilter = () => {
        this.props.setPromotionsFilters({ city: null, startDate: null, endDate: null });
        this.setState({ currentPage: 0 });
        this.props.fetchPromotions();
    }

    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">Акции</h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {this.props.count} Всего
                            </span>
                        </div>                   
                    </div>
                </div>
            </div>
        );
    }

    renderList() {
        return (
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                <List
                    setEditedPromotion={this.props.setEditedPromotion}
                    setDetailPromotion={this.props.setDetailPromotion}
                    items={this.props.promotions}
                    fetchPromotions={this.props.fetchPromotions}
                    deletePromotion={this.props.deletePromotion}
                />
                {
                    this.props.promotions.length > 0 && this.renderPagination()
                }
            </div>
        );
    }

    renderAddForm() {
        return (
            <AddForm
                add={this.props.add}
                setAddPromotion={this.props.setAddPromotion}
                addPromotion={this.props.addPromotion}
                cities={this.props.cities}
                loadingCity={this.props.loadingCity}
                fetchPromotions={this.props.fetchPromotions}
                goods={this.props.goods}
                loadingGoods={this.props.loadingGoods}
                categories={this.props.categories}
            />
        );
    }

    renderDetailForm() {
        return (
            <Detail
               detail={this.props.detail}
               setDetailPromotion={this.props.setDetailPromotion}
               setEditedPromotion={this.props.setEditedPromotion}
            />
        );
    }

    renderEditForm() {
        return (
            <EditForm
                item={this.props.detail}
                edited={this.props.edited}
                setEditedPromotion={this.props.setEditedPromotion}
                setDetailPromotion={this.props.setDetailPromotion}
                updatePromotion={this.props.updatePromotion}
                fetchPromotions={this.props.fetchPromotions}
                cities={this.props.cities}
                loadingCity={this.props.loadingCity}
                goods={this.props.goods}
                loadingGoods={this.props.loadingGoods}
                categories={this.props.categories}
            />
        );
    }

    renderContent() {
        if (this.props.edited) return this.renderEditForm();
        if (this.props.add) return this.renderAddForm();
        if (this.props.detail) return this.renderDetailForm();
        return this.renderList();
    }

    setEditedPromotion = (promotion) => (e) => {
        this.props.setEditedPromotion(promotion);
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.onSearch(); 
        }
    }

    render() {
        if (this.props.loading) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }

        const actionsMobile =[
            { name: 'Добавить акцию', icon: 'flaticon2-plus', action: () => this.addPromotion() }
        ]
        
        return (
            <Content>
                <HeadMobileActionMenu actions={actionsMobile}/>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                        {(window.innerWidth > 1024 || this.props.UImobile.openFilters) &&
                        <div className="filterBlocks" style={window.innerWidth < 1024 ? { marginTop: '5px' } : {}} onKeyDown={this.onKeyDown}>
                            <div className="dateBlock">
                                <DateInput
                                    showPopperArrow={false}
                                    placeholderText="Период от"
                                    selected={this.props.filters.startDate}
                                    onChange={this.onChangeDateInput('startDate')}
                                />
                                <div>-</div>
                                <DateInput
                                    showPopperArrow={false}
                                    placeholderText="Период до"
                                    selected={this.props.filters.endDate}
                                    onChange={this.onChangeDateInput('endDate')}
                                />
                            </div>
                            <div style={{ width: '250px' }}>
                                <Select
                                    isLoading={this.props.loadingCity}
                                    isDisabled={this.props.loadingCity}
                                    value={this.props.filters.city}
                                    closeMenuOnSelect={true}
                                    onChange={this.setPromotionsFilters('city')}
                                    options={this.props.cities.map(item => ({
                                        value: item.id,
                                        label: item.name
                                    }))}
                                    placeholder="Город"
                                    noOptionsMessage={() => 'Нет городов'}
                                />
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <button style={{ marginRight: 5 }} onClick={this.onSearch} className="btn btn-primary">Найти</button>
                                <button style={{ marginRight: 5 }} onClick={this.resetFilter} className="btn btn-danger">Сбросить</button>
                                {window.innerWidth > 1024 && <button disabled={this.props.add} onClick={this.addPromotion} className="btn btn-success">
                                    Добавить акцию
                                </button>}
                            </div>
                            
                        </div>
                        }
                        <Portlet style={{ overflow: 'auto' }} fit={true}>
                            <ErrorContainer field="promotions" style={{ margin: 15 }} hasCloseButton={true} />
                            {this.renderContent()}
                        </Portlet>
                </div>
            </Content>
        );
    }
}



const mapStateToProps = state => {
    const goodsSelector = state => state.good.list;
    const categoriesSelector = state => state.good.categories;

    const normalizeCategorySelector = createSelector(categoriesSelector, (categories) => {
        return categories.map(item => ({ value: item.id, label: item.title }));
    });

    const normalizeGoodsSelector = createSelector(
        goodsSelector,
        categoriesSelector,
        (goods, categories) => {
            const resultCategories = categories.reduce((data, item) => {
                data[item.id] = item.title;
                return data;
            }, {});

            const resultGoods = goods.reduce((data, item) => {
                if (data[item.category.id]) {
                    data[item.category.id].push({ value: item.id, label: item.name });
                } else {
                    data[item.category.id] = [{ value: item.id, label: item.name }];
                }
                return data;
            }, {});
            
            const result = Object.keys(resultGoods).map(key => {
                return ({
                    label: resultCategories[key],
                    options: resultGoods[key],
                });
            }).filter(item => item.label);

            return result;
        }
    );

    return ({
        promotions: state.promotions.list,
        loading: state.loaders.promotions,
        paging: state.promotions.paging,
        filters: state.promotions.filters,
        count: state.promotions.count,
        edited: state.promotions.edited,
        detail: state.promotions.detail,
        add: state.promotions.add,
        cities: state.handbook.cities,
        loadingCity: state.loaders.cities,
        goods: normalizeGoodsSelector(state),
        loadingGoods: state.loaders.goods || state.loaders.category,
        categories: normalizeCategorySelector(state),
        UImobile: state.UImobile
    });
};

const mapDispatchToProps = {
    fetchCities: () => fetchCities(true),
    fetchPromotions: () => fetchPromotions(),
    setEditedPromotion: (promotion) => setEditedPromotion(promotion),
    setDetailPromotion: (promotion) => setDetailPromotion(promotion),
    updatePromotion: (id, data) => updatePromotion(id, data),
    deletePromotion: (id) => deletePromotion(id),
    addPromotion: (data) => addPromotion(data),
    setAddPromotion: (promotion) => setAddPromotion(promotion),
    fetchGoods: () => fetchGoods(),
    fetchCategories: () => fetchCategories(),
    setPromotionsFilters: (filters) => setPromotionsFilters(filters),
    setPromotionsPaging: (paging) => setPromotionsPaging(paging),
    setUIvisible: (visibleFiltersButton, visibleActionButton) => setUIvisible(visibleFiltersButton, visibleActionButton)
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionPage);